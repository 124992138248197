var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vps-detail"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isCurrentLoading)?_c('div',{staticClass:"vps-detail__loader"},[_c('base-loader',{staticClass:"vps-detail__loader-item"})],1):(!_vm.isCurrentLoading && _vm.isServerError)?_c('base-alert',{staticClass:"vps-info__alert",attrs:{"title":_vm.$t('errors.serverError.title'),"icon":"warn"}},[_vm._v(" "+_vm._s(_vm.$t('errors.serverError.text'))+" ")]):(_vm.tariff)?_c('div',{staticClass:"vps-detail__inner"},[_c('div',{staticClass:"vps-detail__content"},[_c('layout-stack-title-menu',{staticClass:"vps-detail__head",attrs:{"title":_vm.tariff.name,"address":_vm.tariff.id,"description":_vm.tariff.description},scopedSlots:_vm._u([{key:"btn",fn:function(){return [_c('v-popover',{attrs:{"placement":"bottom","popover-class":"vps-detail__popover"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('firewall-context-menu',{staticClass:"vps-detail__context-menu",attrs:{"tariff":_vm.tariff,"is-loading":_vm.isCurrentLoading}})]},proxy:true}])},[_c('sidebar-button')],1)]},proxy:true},{key:"button",fn:function(){return [_c('base-button',{staticClass:"subnet-info__create-btn",attrs:{"icon":_vm.isIcon,"color":_vm.isColor,"tooltip":{
                content: _vm.text,
                autoHide: false,
                placement: 'left-start',
                container: false,
                trigger: 'click hover',
              }},on:{"click":function($event){return _vm.clickActionButton(_vm.tariff)}}},[_vm._v(_vm._s(_vm.$t('newFirewall')))])]},proxy:true}])}),_c('tabs',{staticClass:"medium-title",attrs:{"list":_vm.nav,"button":"firewall"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('router-link',{attrs:{"exact-active-class":"active","to":item.to}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}])}),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.tariff)?_c('router-view',{attrs:{"tariff":_vm.tariff}}):_vm._e()],1)],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }