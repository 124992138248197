<template>
  <div class="vps-detail">
    <div class="nav"></div>
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" class="avatar__loading" />
      <div v-else class="vps-detail__inner">
        <div class="vps-detail__content">
          <div class="vps-detail__content-rule">
            <layout-stack-title
              v-if="!!rule"
              :title="rule.name"
              :specs="specsObj"
              type="pool"
              :description="rule.description"
              :status="rule.operating_status"
              :state="rule.provisioning_status"
              class="vps-detail__head"
              ><template #btn>
                <v-popover placement="bottom" popover-class="vps-detail__popover">
                  <sidebar-button /><template #popover>
                    <pool-context-menu
                      :tariff="rule"
                      :is-loading="isCurrentLoading"
                      class="vps-detail__context-menu"
                  /></template> </v-popover
              ></template>
            </layout-stack-title>
          </div>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view
              v-if="ruleid"
              :loading="loading"
              :dataset="listOfMembers"
              :rule="rule"
              :subnet-id="subnetId"
              :ruleid="ruleid"
              :listener="listener"
            ></router-view>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import { OPTariff } from '@/models/OP/OPTariff';
import SidebarButton from '@/components/Buttons/SidebarButton';
import PoolContextMenu from '@/layouts/Stack/pages/Main/components/PoolContextMenu';

export default {
  name: 'Rule',
  components: {
    tabs,
    LayoutStackTitle,
    SidebarButton,
    PoolContextMenu,
    // MainCard,
    // ServerContextMenu,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
    ruleid: {
      type: String,
      required: true,
    },
    balancerid: {
      type: String,
      required: true,
    },
    subnetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCurrentLoading: false,
      localDisk: false,
      isLoading: true,
      stats: {},
      listOfMembers: [],
      isServerError: false,
      isNeedUpdateMembers: false,
      isMobile: true,
    };
  },
  computed: {
    loading() {
      return this.$store.state.moduleStack.updateMembers;
    },
    rule() {
      return this.$store.state.moduleStack.pools.find(x => x.id === this.ruleid);
    },
    listener() {
      if (this.rule && this.rule.listeners && this.rule.listeners.length) {
        return this.$store.state.moduleStack.listeners.find(
          x => x.id === this.rule.listeners[0].id
        );
      } else return '';
    },
    members() {
      // return this.$store.state.moduleStack.members[this.ruleid];
      return this.$store.getters['moduleStack/getCurrentMembers'](this.ruleid);
    },
    ssl() {
      if (this.listener && this.listener.default_tls_container_ref) {
        return this.listener.default_tls_container_ref.split('/containers/')[1];
      } else return '';
    },
    healthmonitor() {
      return this.$store.state.moduleStack.healthmonitors.find(
        x => x.id === this.rule.healthmonitor_id
      );
    },
    specsObj() {
      const specs = this.rule;
      const listener = this.listener;
      if (!this.members && this.listOfMembers.length === 0) this.fetchMembers();
      const member = this.members && this.members.length ? this.members : this.listOfMembers;

      const isOk =
        !!specs && !!listener && Object.keys(specs).length > 0 && Object.keys(listener).length > 0; //&& listOfMembers.length > 0; //&&
      // Object.keys(healthmonitor).length > 0;
      let list = [];
      if (
        isOk &&
        listener.protocol &&
        listener.protocol_port &&
        member &&
        member.find(x => !!x.protocol_port)
      )
        list.push(
          `${listener.protocol === 'TERMINATED_HTTPS' ? 'HTTPS' : listener.protocol}:${
            listener.protocol_port
            // } -> ${specs.protocol}:${member.protocol_port}`
          } -> ${specs.protocol}: ${member.map(x => x.protocol_port).join(', ')}`
        );

      if (isOk && specs.lb_algorithm) {
        if (specs.session_persistence && specs.session_persistence.type) {
          list.push(`${specs.lb_algorithm} (${specs.session_persistence.type})`);
        } else list.push(specs.lb_algorithm);
      }
      return {
        list,
      };
    },
    navRule() {
      return [
        // {
        //   title: this.$t('nav.rule'),
        //   to: { name: 'containerRuleStart' },
        // },
        {
          title: this.$t('nav.rule'),
          to: { name: 'containerRuleStart' },
        },
        {
          title: this.$t('nav.view'),
          to: { name: 'BalancerView' },
        },
      ];
    },
    navRaw() {
      return [
        {
          title: this.$t('nav.servers'),
          to: { name: 'RuleServers' },
          access: true,
        },
        {
          title: this.$t('nav.alg'),
          to: { name: 'RuleAlgorithm' },
          access: true,
        },
        {
          title: this.$t('nav.check'),
          to: { name: 'RuleCheck' },
          access: true,
        },
        {
          title: this.$t('nav.connection'),
          to: { name: 'RuleConnect' },
          access: true,
        },

        {
          title: this.$t('nav.headers'),
          to: { name: 'RuleHeaders' },
          access: this.rule && this.rule.protocol.includes('HTTP'),
        },
        {
          title: this.$t('nav.ssl'),
          to: { name: 'RuleSsl' },
          access: this.listener && this.listener.protocol === 'TERMINATED_HTTPS',
        },
        {
          title: this.$t('nav.view'),
          to: { name: 'PoolView' },
          access: true,
        },
      ];
    },
    nav() {
      return this.navRaw.filter(i => i.access);
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    // updateMembers() {
    //   return this.$store.state.moduleStack.updateMembers;
    // },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
    loading: {
      async handler(event) {
        // console.log(event, this.isLoading);
        if (event)
          await this.fetchMembers().then(() => {
            // this.loading = false;
            // this.isLoading = false;
          });
      },
      immediate: true,
    },
    listOfMembers: {
      handler: function (event) {
        this.ListOfProtocols = event.map(x => x.protocol_port).join(', ');
      },
    },
    members: {
      immediate: true,
      handler(event) {
        // console.log(event);
        this.isLoading = false;
        if (event && event.length) this.listOfMembers = event;
      },
    },
  },
  mounted() {
    // this.fetchServer();
    // } else {
    //   this.fetchServer();
    // this.getLocalDisk();
    // }
    this.$nextTick(() => this.setIsMobile());
  },
  beforeRouteLeave(_to, _from, next) {
    this.status = {};
    next();
  },
  methods: {
    // getTariff(id) {
    //   return this.$store.dispatch('moduleStack/getServerInfo', id);
    // },
    async fetchMembers() {
      await this.$store
        .dispatch('moduleStack/getPoolMembers', { id: this.ruleid })
        .then(async data => {
          // console.log(data);
          this.listOfMembers = data ? data : [];
          // this.member = data ? data : [];
        });
    },
    newRule() {
      // console.log('newRule');
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "newRule": "Добавить правило",
      "back": "К правилам",
      "nav": {
        "servers": "Серверы",
        "alg": "Алгоритм",
        "ssl": "SSL сертификаты",
        "check": "Проверка",
        "headers": "Заголовки",
        "rule": "Правила",
        "view": "Обзор",
        "connection": "Соединения",
        "log": "Лог"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.nav {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

}
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
    &-rule {
      //display:flex;
      //justify-content:space-between;
    }
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
